import axios from "axios";
import router from "@/router";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";
import { convertUtcToLocal, deleteCookie, getCookie, setCookie } from "@/helper";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["authorization"] = "Bearer " + getCookie("authorization");
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {

  sendCode({ dispatch, commit }, data) {
    console.log(data)
    commit('setCheckCode', false);
    commit("setLoginCheck", true);
    axios
      .post("auth/createOtp", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data.expireDate)
        commit('setCheckCode', true);
        commit("setTimer", convertUtcToLocal(res.data.expireDate))
        commit("setSuccess", alert.success.sentCode, { root: true });
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  login({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    console.log(data);
    let query = `?otp.phoneNumber=${data.mobile}&otp.Code=${data.code}`;
    console.log(query);
    axios
      .get("auth/OtpCheckResult" + query, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data.isSuccess)
        if (res.data.authorization) {
          commit('authUser', res.data.authorization);
          setCookie("authorization", res.data.authorization, 0.5)
          axios.defaults.headers.common["authorization"] =
            "Bearer " + res.data.authorization;
          router.push("/");
        }
        setTimeout(() => {
          commit("setSuccess", alert.success.login, { root: true });
        }, 800);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  register({ dispatch, commit }, data) {
    commit("setLoginCheck", true);
    axios
      .post('register', data)
      .then(res => {
        commit('authUser', res.data.data);
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('user', res.data.data.user);
        axios.defaults.headers.common["authorization"] =
          "Bearer " + res.data.data.token;
        router.push({ name: 'Dashboard' });
        setTimeout(() => {
          commit('setSuccess', res.data.message, { root: true });
        }, 1000);
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error)
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoginCheck", false);
        }, 800);
      });
  },

  resetPassword({ dispatch }, data) {
    axios
      .put("resetPassword", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.resetPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  forgotPassword({ dispatch, commit }, data) {
    commit('setForgotBtn', true);
    axios
      .put("forgotPassword", data)
      .then((res) => {
        commit('setForgotCheck', false);
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.forgotPassword,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit('setForgotBtn', false);
        }, 1000);
      });
  },

  logout({ dispatch, commit }) {
    axios
      .post('Auth/Logout')
      .then(() => {
        deleteCookie("authorization")
        axios.defaults.headers.common["authorization"] = "";
        commit("clearAuth");
        router.push({ name: "Login" });
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error)
      })


  },

  getCurrentUser({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get('auth/GetProfile')
      .then(res => {
        console.log(res.data)
        commit('setCurrentUser', res.data)
      })
      .catch(error => {
        dispatch('errorGETHandler', error, { root: true })
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  editProfile({ commit, dispatch }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("profile", data)
      .then(res => {
        dispatch('successPOSTHandler',
          {
            server: res,
            message: alert.success.editProfile,
            redirect: 'Dashboard'
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editProfileAvatar({ dispatch }, data) {
    axios
      .post("profile/avatar", data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        dispatch('successPOSTHandler',
          {
            server: res,
            message: alert.success.editProfile,
            redirect: false
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
  },

  editPassword({ commit, dispatch }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("profile/password", data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        dispatch('successPOSTHandler',
          {
            server: res,
            message: alert.success.changePassword,
            redirect: false
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  // Alert and Errors Handler ------------------------------------------------------
  successPOSTHandler({ commit }, data) {
    if (data.server && data.server.status === 200) {
      commit("setSuccess", data.message, { root: true });
      commit("setPostCheck", true, { root: true });
      setTimeout(() => {
        commit("setPostCheck", false, { root: true });
      }, 800);
      if (data.redirect) {
        setTimeout(() => {
          router.push({ name: data.redirect });
        }, 2500);
      }
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
      commit("setPostCheck", false, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
      commit("setPostCheck", false, { root: true });
    }
  },

  successPOSTHandlerSimple({ commit }, data) {
    if (data.server && data.server.status === 200) {
      commit("setSuccess", data.message, { root: true });
    } else if (data.server.data && data.server.data.message != "") {
      commit("setError", data.server.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },

  errorPOSTHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data == "updateBusiness") {
      commit("setError", alert.error.updateBusiness, { root: true });
    } else if (data.response && data.response.status === 403) {
      commit("setError", alert.error.access, { root: true });
    } else if (data.response && data.response.status === 401) {
      dispatch("logout", null, { root: true });
    } else if (data?.response?.data?.message && data?.response?.data?.message != "") {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },

  errorGETHandler({ dispatch, commit }, data) {
    commit("setPostCheck", false, { root: true });
    if (data.response && data.response.status === 403) {
      router.push({ name: "Page403" });
    } else if (data.response && data.response.status === 401) {
      dispatch("logout", null, { root: true });
    } else if (data.response.data.message && data.response.data.message != "") {
      commit("setError", data.response.data.message, { root: true });
    } else {
      commit("setError", alert.error.server, { root: true });
    }
  },

};
